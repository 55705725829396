import {
  useTranslate,
  IResourceComponentsProps,
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
} from "@pankod/refine-antd";
import { ILanguage } from "interfaces";

export const LanguageList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { tableProps, sorter } = useTable<ILanguage>({
    initialSorter: [
      {
        field: "lang_id",
        order: "desc",
      },
    ],
  });

  return (
    <List>
      <Table {...tableProps} rowKey="lang_id">
        <Table.Column
            dataIndex="lang_id"
            key="lang_id"
            title={t("language.fields.lang_id")}
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("lang_id", sorter)}
            sorter
        />
        <Table.Column
            dataIndex="lang_code"
            key="lang_code"
            title={t("language.fields.lang_code")}
            render={(value) => <TextField value={value} />}
        />
        <Table.Column
            dataIndex="name"
            key="name"
            title={t("language.fields.name")}
            render={(value) => <TextField value={value} />}
        />
        <Table.Column
            dataIndex="created_at"
            key="created_at"
            title={t("parameter.fields.created_at")}
            render={(value) => <DateField value={value} format="LLL" />}
        />
        <Table.Column<ILanguage>
            title={t("table.actions")}
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <EditButton hideText size="small" recordItemId={record.lang_id} />
                <ShowButton hideText size="small" recordItemId={record.lang_id} />
                <DeleteButton hideText size="small" recordItemId={record.lang_id} />
              </Space>
            )}
        />
      </Table>
    </List>
  );
};
