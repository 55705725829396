import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  useForm,
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IUser } from "interfaces";

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { formProps, saveButtonProps } = useForm<IUser>();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item label={t("user.fields.email")} name="email" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label={t("user.fields.password")} name="password" rules={[{ required: true }]}>
          <Input type={ "password" } />
        </Form.Item>
      </Form>
    </Create>
  );
};
