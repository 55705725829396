import { AuthProvider } from "@pankod/refine-core";
import { AxiosInstance } from "axios";

const authProvider = (
    apiUrl: string,
    httpClient: AxiosInstance,
): AuthProvider => ({
    login: async ({ username, password }) => {
        const { data, status } = await httpClient.post(
            apiUrl,
            { email: username, password: password },
        );

        if (status === 200) {
            localStorage.setItem("api-token", data.api_token);

            // set header axios instance
            httpClient.defaults.headers.common['Authorization'] = `Bearer ${data.jwt}`

            return Promise.resolve();
        }
        return Promise.reject();
    },
    logout: () => {
        localStorage.removeItem("api-token");
        return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
        const token = localStorage.getItem("api-token");
        if (token) {
            httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`
            return Promise.resolve();
        }

        return Promise.reject();
    },
    getPermissions: () => Promise.resolve(),
    getUserIdentity: async () => {
        return Promise.resolve({
            "email": "hardcoded@email.com",
        });
    },
});

export default authProvider;