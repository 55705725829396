import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  Select,
  useSelect,
  useForm,
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import {IGameType, ILanguage, IQuiz, IQuizCategory} from "interfaces";

const { TextArea } = Input;

export const QuizCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { formProps, saveButtonProps } = useForm<IQuiz>();

  const { selectProps: quizCategorySelectProps } = useSelect<IQuizCategory>({
    resource: "quiz-category",
    optionLabel: "name",
    optionValue: "quiz_category_id",
  });

  const { selectProps: languageSelectProps } = useSelect<ILanguage>({
    resource: "language",
    optionLabel: "name",
    optionValue: "lang_code",
  });

  const { selectProps: gameTypeSelectProps } = useSelect<IGameType>({
    resource: "game-type",
    optionLabel: "name",
    optionValue: "game_type_id",
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
      <Form.Item label={t("quiz.fields.question")} name="question" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label={t("quiz.fields.answer1")} name="answer1" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label={t("quiz.fields.answer2")} name="answer2" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label={t("quiz.fields.answer3")} name="answer3" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label={t("quiz.fields.answer4")} name="answer4" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item label={t("quiz.fields.correct_answer")} name="correct_answer" rules={[{ required: true }]}>
          <Select
            options={[
              { label: 1, value: 1 },
              { label: 2, value: 2 },
              { label: 3, value: 3 },
              { label: 4, value: 4 },
            ]}
          />
        </Form.Item>

        <Form.Item
          label={t("quiz.fields.quiz_category_name")}
          name={["quiz_category_id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...quizCategorySelectProps} />
        </Form.Item>

        <Form.Item
          label={t("quiz.fields.lang")}
          name={["lang"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...languageSelectProps} />
        </Form.Item>

        <Form.Item
          label={t("quiz.fields.game_type_name")}
          name={["game_type_id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...gameTypeSelectProps} />
        </Form.Item>

        <Form.Item label={t("quiz.fields.explanation")} name="explanation" rules={[{ required: true }]}>
          <TextArea rows={10} />
        </Form.Item>

        <Form.Item label={t("quiz.fields.keywords")} name="keywords" rules={[{ required: false }]}>
          <Select mode="tags" />
        </Form.Item>

        <Form.Item label={t("quiz.fields.level")} name="level" rules={[{ required: true }]}>
          <Select
              options={[
                { label: 1, value: 1 },
                { label: 2, value: 2 },
                { label: 3, value: 3 },
              ]}
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
