import {
  useTranslate,
  IResourceComponentsProps,
  useShow,
} from "@pankod/refine-core";
import { Show, Typography } from "@pankod/refine-antd";

import { IQuizCategory } from "interfaces";

const { Title, Text } = Typography;

export const QuizCategoryShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { queryResult } = useShow<IQuizCategory>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{t("quizCategory.fields.name")}</Title>
      <Text>{record?.name}</Text>

      <Title level={5}>{t("quizCategory.fields.idCustom")}</Title>
      <Text>{record?.id_custom}</Text>

      <Title level={5}>{t("quizCategory.fields.imagen")}</Title>
      <Text>{record?.imagen}</Text>
    </Show>
  );
};
