import {
  useTranslate,
  IResourceComponentsProps,
  useShow,
} from "@pankod/refine-core";
import { Show, TagField, Typography } from "@pankod/refine-antd";

import { IQuiz } from "interfaces";

const { Title, Text } = Typography;

export const QuizShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { queryResult } = useShow<IQuiz>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{t("quiz.fields.question")}</Title>
      <Text>{record?.question}</Text>

      <Title level={5}>{t("quiz.fields.answer1")}</Title>
      <Text>{record?.answer1}</Text>

      <Title level={5}>{t("quiz.fields.answer2")}</Title>
      <Text>{record?.answer2}</Text>

      <Title level={5}>{t("quiz.fields.answer3")}</Title>
      <Text>{record?.answer3}</Text>

      <Title level={5}>{t("quiz.fields.answer4")}</Title>
      <Text>{record?.answer4}</Text>

      <Title level={5}>{t("quiz.fields.correctAnswer")}</Title>
      <Text>{record?.correct_answer}</Text>

      <Title level={5}>{t("quiz.fields.quiz_category_name")}</Title>
      <Text>{record?.quiz_category_name}</Text>

      <Title level={5}>{t("quiz.fields.lang")}</Title>
      <Text>{record?.lang}</Text>

      <Title level={5}>{t("quiz.fields.explanation")}</Title>
      {record?.explanation.split('\n').map((item, key) => {
        return <Text>{item}<br/></Text>
      })}

      <Title level={5}>{t("quiz.fields.keywords")}</Title>
      {record?.keywords.map((value, index) => <TagField value={value}/>)}

      <Title level={5}>{t("quiz.fields.level")}</Title>
      <Text>{record?.level}</Text>

      <Title level={5}>{t("quiz.fields.game_type_name")}</Title>
      <Text>{record?.game_type_name}</Text>
    </Show>
  );
};
