import {
  useTranslate,
  IResourceComponentsProps,
  useShow,
} from "@pankod/refine-core";
import { Show, Typography } from "@pankod/refine-antd";

import { IGameType } from "interfaces";

const { Title, Text } = Typography;

export const GameTypeShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { queryResult } = useShow<IGameType>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{t("gameType.fields.name")}</Title>
      <Text>{record?.name}</Text>
    </Show>
  );
};
