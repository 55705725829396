import {
  useTranslate,
  IResourceComponentsProps,
  useShow,
} from "@pankod/refine-core";
import { Show, Typography } from "@pankod/refine-antd";

import { INews } from "interfaces";

const { Title, Text } = Typography;

export const NewsShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { queryResult } = useShow<INews>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{t("news.fields.title")}</Title>
      <Text>{record?.title}</Text>

      <Title level={5}>{t("news.fields.url")}</Title>
      <Text>{record?.url}</Text>

      <Title level={5}>{t("news.fields.description")}</Title>
      <Text>{record?.description}</Text>

      <Title level={5}>{t("news.fields.date")}</Title>
      <Text>{record?.date}</Text>
    </Show>
  );
};
