import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  Layout,
  LoginPage,
  ReadyPage,
  ErrorComponent,
} from "@pankod/refine-antd";
import "@pankod/refine-antd/dist/styles.min.css";
import routerProvider from "@pankod/refine-react-router-v6";
import httpClient from "./httpClient";
import authProvider from "./authProvider";
import dataProvider from "./dataProvider";
import { QuizList, QuizCreate, QuizShow, QuizEdit } from "pages/quiz";
import { QuizCategoryList, QuizCategoryCreate, QuizCategoryEdit, QuizCategoryShow } from "pages/quizCategory";
import { LanguageList, LanguageCreate, LanguageEdit, LanguageShow } from "pages/language";
import { GameTypeList, GameTypeCreate, GameTypeEdit, GameTypeShow } from "pages/gameType";
import { RankingTypeList, RankingTypeCreate, RankingTypeEdit, RankingTypeShow } from "pages/rankingType";
import { MessageList, MessageCreate, MessageEdit, MessageShow } from "pages/message";
import { NewsList, NewsCreate, NewsEdit, NewsShow } from "pages/news";
import { UserList, UserCreate, UserEdit, UserShow } from "pages/user";
import { useTranslation } from "react-i18next";
import { Header } from "components/layout";
import "./index.css"

function App() {
  const BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:8080";
  const BASE_PATH = process.env.REACT_APP_BASE_PATH || "/";
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const { RouterComponent } = routerProvider;

  const CustomRouterComponent = () => <RouterComponent basename={BASE_PATH} />;

  return (
    <Refine
      notificationProvider={notificationProvider}
      Layout={Layout}
      LoginPage={LoginPage}
      ReadyPage={ReadyPage}
      catchAll={<ErrorComponent />}
      routerProvider={{
        ...routerProvider,
        RouterComponent: CustomRouterComponent,
    }}
      authProvider={authProvider(`${BASE_URL}/api/v2/user/login`, httpClient)}
      dataProvider={dataProvider(`${BASE_URL}/api/v2`, httpClient)}
      resources={[
        {
          name: "quiz",
          list: QuizList,
          create: QuizCreate,
          edit: QuizEdit,
          show: QuizShow,
        },
        {
          name: "quiz-category",
          list: QuizCategoryList,
          create: QuizCategoryCreate,
          edit: QuizCategoryEdit,
          show: QuizCategoryShow,
        },
        {
          name: "language",
          list: LanguageList,
          create: LanguageCreate,
          edit: LanguageEdit,
          show: LanguageShow,
        },
        {
          name: "game-type",
          list: GameTypeList,
          create: GameTypeCreate,
          edit: GameTypeEdit,
          show: GameTypeShow,
        },
        {
          name: "ranking-type",
          list: RankingTypeList,
          create: RankingTypeCreate,
          edit: RankingTypeEdit,
          show: RankingTypeShow,
        },
        {
          name: "message",
          list: MessageList,
          create: MessageCreate,
          edit: MessageEdit,
          show: MessageShow,
        },
        {
          name: "news",
          list: NewsList,
          create: NewsCreate,
          edit: NewsEdit,
          show: NewsShow,
        },
        {
          name: "user",
          list: UserList,
          create: UserCreate,
          edit: UserEdit,
          show: UserShow,
        },
      ]}
      i18nProvider={i18nProvider}
      Header={Header}
    />
  );
}

export default App;
