import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  InputNumber,
  useForm,
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IQuizCategory } from "interfaces";

export const QuizCategoryCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { formProps, saveButtonProps } = useForm<IQuizCategory>();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item label={t("quizCategory.fields.name")} name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label={t("quizCategory.fields.idCustom")} name="id_custom" rules={[{ required: true }]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label={t("quizCategory.fields.imagen")} name="imagen" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </Create>
  );
};
