import axios from "axios";
import { HttpError } from "@pankod/refine-core";

const httpClient = axios.create()

httpClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const customError: HttpError = {
            ...error,
            message: error.response?.data?.message,
            statusCode: error.response?.status,
        };

        return Promise.reject(customError);
    },
);

export default httpClient
