import {
  useTranslate,
  IResourceComponentsProps,
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
} from "@pankod/refine-antd";
import { IMessage } from "interfaces";

export const MessageList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { tableProps, sorter } = useTable<IMessage>({
    initialSorter: [
      {
        field: "message_id",
        order: "desc",
      },
    ],
  });

  return (
    <List>
      <Table {...tableProps} rowKey="message_id">
        <Table.Column
          dataIndex="message_id"
          key="message_id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("message_id", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="message"
          key="message"
          title={t("message.fields.message")}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="link"
          key="link"
          title={t("message.fields.link")}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="created_at"
          key="created_at"
          title={t("parameter.fields.created_at")}
          render={(value) => <DateField value={value} format="LLL" />}
        />
        <Table.Column<IMessage>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.message_id} />
              <ShowButton hideText size="small" recordItemId={record.message_id} />
              <DeleteButton hideText size="small" recordItemId={record.message_id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
